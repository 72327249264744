import * as Sentry from "@sentry/react";

export const initializeSentry = () => {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
            Sentry.replayIntegration()
        ],
        // Session Replay
        replaysSessionSampleRate: 1,
        replaysOnErrorSampleRate: 1.0
    });
}
