import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {isUserAbleToStartSession, isUserLoggedIn, startScan} from "../../../helpers";
import {TGIcon, TGText} from "../../TGElements";
import {LottiePlayer} from "../../index";

const ScanCenterIcon = () => {
    const {activeSession} = useSelector(state => state.session);
    const history = useHistory()
    const [isDisabled, setDisabled] = useState(false);

    const isActiveSession = Object.keys(activeSession).length;
    const isDC = activeSession?.connector_type === 'DC';
    const percent = activeSession?.battery_percent;
    const icon = isActiveSession ? !isDC ? "big-active-charge" : "active-charge" : "new-scan";
    const iconWidth = isActiveSession ? !isDC ? 32 : 26 : 38;
    const iconHeight = isActiveSession ? !isDC ? 44 : 26 : 38;

    const onClick = async () => {
        if (isDisabled) return;
        setDisabled(true)
        if (!isUserLoggedIn()) return history.push("/register-phone")
        if (Object.keys(activeSession).length) return history.push("/active-session")
        const isAble = await isUserAbleToStartSession()
        setDisabled(false)
        if (!isAble) return;
        const response = await startScan();
        if (!response.scanner) {
            return history.push("/start-session");
        }
        history.push("/scanner");
    }

    return (
        <div onClick={onClick} className={`TGMapScanButton ${isActiveSession && 'activeSession'}`}>
            <TGIcon name={icon} width={iconWidth} height={iconHeight}/>
            {isDC && <TGText color={'#fff'} fontSize={24} fontWeight={600}><sup>%</sup>{percent}</TGText>}
            <LottiePlayer animationName={"scan-animation"} loop cssClass={"TGMapScanAnimation"}/>
        </div>
    )
};

export default ScanCenterIcon;