//React
import {useParams} from "react-router";
import {useEffect, useState} from "react";
//Components
import {TGButton, TGText} from "../components";
//Helpers
import {useTranslation} from "react-i18next";
import {getInvoiceDetail} from "../helpers";
import {
  Header,
  Pricing,
  Summary,
  Vehicle,
} from "../components/Invoices";
import {useDispatch, useSelector} from "react-redux";
import {Alert} from "../helpers/alert";
import {InAppBrowser} from "@awesome-cordova-plugins/in-app-browser";
import {setLoading} from "../redux/slices/globalSlice";
import {IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import {TGBackButton} from "../components/TGElements";


const AddressStyle = {
  color: "var(--tg-secondary-text)",
  textAlign: "center",
  fontSize: "12px",
  fontWeight: 400,
  width: "100%",
  textTransform: "unset",
  lineHeight: "15px",
}

const InvoiceDetail = () => {
  const {t} = useTranslation();
  const params = useParams();
  const [invoice, setInvoice] = useState({});
  const {company: {address, name, tel, mail}} = useSelector(state => state.config)
  const dispatch = useDispatch();


  const getDetail = async (id) => {
    const result = await getInvoiceDetail(id)
    setInvoice(result);
  };

  useEffect(() => {
    getDetail(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getPdf = async () => {
    dispatch(setLoading(true));
    const user = localStorage.getItem('user')
    const token = JSON.parse(user).token
    const url = process.env.REACT_APP_API_URL + 'v2/invoice/get-pdf/' + invoice?.payment_id
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    const data = await res.blob()
    if (data.type === 'application/json') {
      // error blob to json when the response is an error
      const json = await data.text()
      const error = JSON.parse(json)
      Alert.error(error.message)
      return
    }

    // open pdf in new tab
    if (data.type === 'application/pdf') {
      const pdfUrl = URL.createObjectURL(data)
      InAppBrowser.create(pdfUrl, '_blank', {location: 'no'});
    }
    dispatch(setLoading(false));
  }

  return (
    <IonPage>
      <IonHeader className={"ion-no-border"}>
        <IonToolbar className={"TGPageHeader"}>
          <IonButtons slot="start">
            <TGBackButton/>
          </IonButtons>
          <IonTitle className={"ion-text-capitalize"}>{t("invoice-detail")}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className={"TGPageContent ion-padding-start ion-padding-end"}>
        <Header id={invoice?.payment_id} isBusiness={invoice?.usage_type}/>

        <Summary
          createdAt={invoice?.created_at}
          station={{
            name: invoice?.location_name,
            station_id: invoice?.location_id
          }}
          summary={{
            charging_duration: invoice?.charging_duration,
            consumed_amount: invoice?.charging_electricity_consumption,
            charging_fee: invoice?.charging_price
          }}
        />

        <Vehicle
          displayName={invoice?.name}
          plate={invoice?.plate}
          modelText={invoice?.vehicle}
        />

        <Pricing
          chargingCost={invoice?.charging_session_cost}
          occupationCost={invoice?.occupation_price}
          totalCost={invoice?.total_amount}
          statusName={invoice?.status_name}
          paymentType={invoice?.payment_type}
        />

        {/* TODO: data is not ready for card last4 digit code */}
        {/*<CardSummary isLoading={true}/>*/}

        <TGText styles={{...AddressStyle}}>{name}</TGText>
        <TGText styles={{...AddressStyle}}>{tel} - {mail}</TGText>
        <TGText styles={{...AddressStyle}} margin={'0 0 30px 0'}>{address}</TGText>
        <TGButton onButtonClick={getPdf} margin={"10px 0 30px"}>{t("download-pdf")}</TGButton>
      </IonContent>
    </IonPage>
  );
};

export default InvoiceDetail;
