import React from 'react'
import {TGButton, TGInput, TGText, TGTextarea} from '../TGElements'
import {t} from 'i18next'
import {sendContactForm} from '../../helpers'
import {useForm} from 'react-hook-form'
import {useHistory} from "react-router-dom";
import {IonIcon, useIonViewWillEnter} from "@ionic/react";
import {documentAttachOutline,document, image} from 'ionicons/icons'
import Compressor from "compressorjs";
import {Alert} from "../../helpers/alert";

const ContactForm = () => {
  const history = useHistory()
  const [loading, setLoading] = React.useState(false)
  const [file, setFile] = React.useState(null)
  const {register, handleSubmit, formState: {errors}, reset} = useForm();

  const onSubmit = async data => {
    data["department_id"] = 1
    data["file"] = file
    setLoading(true)
    const {success} = await sendContactForm(data)
    setLoading(false)
    if (success) {
      history.replace("/menu/support/tickets")
    }
  }


  const handleCompressedUpload = (e) => {
    const allowedTypes = ['image/jpeg', 'image/png', 'image/webp', "application/pdf"];
    const image = e.target.files[0];
    const fileOrBlob = image instanceof File || image instanceof Blob
    if(allowedTypes.includes(image.type) && fileOrBlob){
      if (image.type === 'application/pdf') {
        setFile(image)
        return
      }
      new Compressor(image, {
        quality: 0.8,
        success: (compressedResult) => {
          const image = new File([compressedResult], compressedResult.name);
          setFile(image)
        },
      });
    }
    else {
      Alert.error(t('img-type-is-not-allowed'))
    }
  };

  useIonViewWillEnter(()=>{
    setFile(null)
  })

  return (
    <form className='TicketForm' onSubmit={handleSubmit(onSubmit)}>
      <TGInput
        label={t("subject")}
        borderRadius={10}
        margin={"0 0 0.4rem 0"}
        error={!!errors.title}
        errorText={errors.title?.message}
        register={register("title", {
          required: t(`please-enter-valid-title`),
          minLength: {value: 3, message: t("min-length-title", {length: 3})}
        })}
      />
      <TGTextarea
        placeholder={t("message")}
        counter={true}
        error={!!errors.message}
        errorText={errors.message?.message}
        register={register("message", {
          required: t(`please-enter-valid-message`),
          minLength: {value: 25, message: t("min-length-message", {length: 25})}
        })}
      />

      <label className="fileAttachmentWrapper" htmlFor="file-upload">
        <input id="file-upload" type="file" accept="image" onChange={handleCompressedUpload}/>
        <div className={'fileDescWrapper'}>
          {file && <IonIcon icon={ file.type === 'application/pdf' ? document : image } size={'small'} id={'text-icon'}/>}
          <TGText color={"var(--tg-secondary-text)"} wrapWithDots={true} width={'100%'}>
            {file?.name ?? t("file-attachment")}
          </TGText>
        </div>
        <IonIcon icon={documentAttachOutline} slot={'icon-only'} id={'icon'} />
      </label>

      <TGButton type="submit" cssClass={'TGButton submitBtn'}>
        <TGText color={"var(--tg-white)"}>
          {loading ? t('loading') : t("submit")}
        </TGText>
      </TGButton>
    </form>
  )
}

export default ContactForm